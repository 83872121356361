import React, { useRef, useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import notificationIcon from "../../assets/notification.svg";
import Loading from "../../components/Loading/Loading";
import { useOnClickOutside } from "../../CustomHooks/useOnClickOutside";
import "../../index.scss";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { fetchNotifications, fetchUpdatedNotifications } from "../../Redux/features/NotificationSlice/NotificationSlice";
import { posttransactionDetailsData } from "../../Redux/features/Transaction/TransactionListingSlice";

import { toast } from "react-toastify";
import { update_transaction_data } from "../../Redux/features/Orders/OrderListingSlice";

import socket from "../../socket";
import { update_kill_counter } from "../../Redux/features/Dashboard/DashBoardSlice";
import { updatesetSelectedID } from "../../Redux/features/MainDashboard/MainDashBoardSlice";
function Notification(props: any) {
  const { notification, setRightHoldingDrawer } = props;
  // const socket: any = useAppSelector((state) => state.dashBoard.socket_connect_obj);
  const [Show, setShow] = useState(false);
  const [isbadge, setIsbadge] = useState(false);
  const selectedPortfolios = useAppSelector((state) => state.dashBoard.portfoliosIds); // console.log(notification);
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      if (Show) {
        setIsbadge(false);
      }
    })();

    return () => {};
    // eslint-disable-next-line
  }, [Show]);

  useEffect(() => {
    if (isbadge) {
      dispatch(fetchUpdatedNotifications({ page: 1 }));
      setIsbadge(false);
    }
  }, [isbadge]);
  useEffect(() => {
    // console.log("notification :: befrore socket ");
    socket.on("new-notification-channel:App\\Events\\NotificationEvent", (data: any) => {
      console.log("notification :: before for ", data);
      for (var i in data) {
        let messages = data[i];
        for (var j in messages) {
          if (j === "-1" && messages[j].message === "transactions") {
            //set code for update transaction list
          } else if (selectedPortfolios.includes(j)) {
            let show_toaster = messages[j].show_toaster;

            let trade_record = messages[j].trade_record;

            var user_id = localStorage.getItem("login-id");
            var notification_user_id = messages[j].user_id;
            dispatch(update_transaction_data(trade_record));
            let trades_count = messages[j].trades_count;
            dispatch(update_kill_counter(trades_count));

            if (show_toaster) {
              let title = messages[j].title;
              // set code for update notification list
              let description = messages[j].description;
              dispatch(fetchNotifications({ page: 1 }));
              toast.success(
                <>
                  <p className="font-bold">{title}</p>
                  <p className="text-xs">{description}</p>
                </>,
                {
                  position: "top-right",
                  autoClose: 1000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                }
              );
            }
            setIsbadge(true);
          }
        }
      }
      // this.isIexStreamStatus = !data;
    });

    return () => {
      // socket.off();
      // this now gets called when the component unmounts
      // socket.off("security_subscription");
    };
    // eslint-disable-next-line
  }, [socket, selectedPortfolios]);
  // const trade_notification_data: any = useAppSelector((state) => state.dashBoard.trade_notification_data);
  // useEffect(() => {
  //   console.log("notification :: befrore socket ");
  //   if (trade_notification_data) {
  //     console.log("notification :: before for ", trade_notification_data);
  //     for (var i in trade_notification_data) {
  //       let messages = trade_notification_data[i];
  //       for (var j in messages) {
  //         if (j === "-1" && messages[j].message === "transactions") {
  //           //set code for update transaction list
  //         } else if (selectedPortfolios.includes(j)) {
  //           let show_toaster = messages[j].show_toaster;
  //           let trade_record = messages[j].trade_record;

  //           var user_id = localStorage.getItem("login-id");
  //           var notification_user_id = messages[j].user_id;
  //           dispatch(update_transaction_data(trade_record));

  //           if (show_toaster) {
  //             let title = messages[j].title;
  //             // set code for update notification list
  //             let description = messages[j].description;
  //             dispatch(fetchNotifications({ page: 1 }));
  //             toast.success(
  //               <>
  //                 <p className="font-bold">{title}</p>
  //                 <p className="text-xs">{description}</p>
  //               </>,
  //               {
  //                 position: "top-right",
  //                 autoClose: 1000,
  //                 hideProgressBar: false,
  //                 closeOnClick: true,
  //                 pauseOnHover: true,
  //                 draggable: true,
  //                 progress: undefined,
  //                 theme: "colored",
  //               }
  //             );
  //           }
  //           setIsbadge(true);
  //         }
  //       }
  //     }
  //   }

  //   return () => {
  //     // socket.off();
  //     // this now gets called when the component unmounts
  //     // socket.off("security_subscription");
  //   };
  //   // eslint-disable-next-line
  // }, [trade_notification_data, selectedPortfolios]);
  const ref: any = useRef(null);
  useOnClickOutside(ref, () => setShow(false));

  const LoadMoreNotifications = () => {
    // console.log("LoadMoreNotifications");
    if (notification.next_page_index !== 0) dispatch(fetchNotifications({ page: notification.next_page_index }));
  };

  return (
    <div ref={ref} className={`${Show === true ? "openNoti" : ""} relative ml-10 current-design-btn`}>
      <button id="theard-element" onClick={() => setShow((prev) => !prev)} className="new-btn-2 notification-group" type="submit">
        <span className="new-btn-2-span">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path
              className="innerColor"
              id="portfolio_blue"
              // fill="#071932"
              d="M208 16c0-8.8 7.2-16 16-16s16 7.2 16 16l0 16.8c80.9 8 144 76.2 144 159.2l0 29.1c0 43.7 17.4 85.6 48.3 116.6l2.8 2.8c8.3 8.3 13 19.6 13 31.3c0 24.5-19.8 44.3-44.3 44.3L44.3 416C19.8 416 0 396.2 0 371.7c0-11.7 4.7-23 13-31.3l2.8-2.8C46.6 306.7 64 264.8 64 221.1L64 192c0-83 63.1-151.2 144-159.2L208 16zm16 48C153.3 64 96 121.3 96 192l0 29.1c0 52.2-20.7 102.3-57.7 139.2L35.6 363c-2.3 2.3-3.6 5.4-3.6 8.7c0 6.8 5.5 12.3 12.3 12.3l359.4 0c6.8 0 12.3-5.5 12.3-12.3c0-3.3-1.3-6.4-3.6-8.7l-2.8-2.8c-36.9-36.9-57.7-87-57.7-139.2l0-29.1c0-70.7-57.3-128-128-128zM193.8 458.7c4.4 12.4 16.3 21.3 30.2 21.3s25.8-8.9 30.2-21.3c2.9-8.3 12.1-12.7 20.4-9.8s12.7 12.1 9.8 20.4C275.6 494.2 251.9 512 224 512s-51.6-17.8-60.4-42.7c-2.9-8.3 1.4-17.5 9.8-20.4s17.5 1.4 20.4 9.8z"
            />
          </svg>
          {isbadge && <span className="badge-counter"></span>}
        </span>
        <div className="tooltiptext tool-right">
          <p>Notifications</p>
        </div>
      </button>
      {Show && (
        <div className="absolute border z-50 right-[10px] h-[35vh] min-w-[20vw] bg-layoutBg rounded text-tabItemText overflow-hidden notification-main">
          <div id="scrollableDiv" style={{ height: "100%", overflowY: "scroll" }}>
            <InfiniteScroll
              dataLength={notification?.data.length}
              next={LoadMoreNotifications}
              hasMore={notification?.data.length === notification.total_records ? false : true}
              loader={<></>}
              scrollableTarget="scrollableDiv"
              style={{ overflow: "hidden" }}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>No More Records</b>
                </p>
              }
            >
              {notification.notification_status === "loading" ? (
                <Loading isMaximized="minimize" />
              ) : notification?.data.length ? (
                notification?.data.map((item: any, index: any) => (
                  <div
                    className={`${
                      index % 2 === 0
                        ? "text-left text-[12px] bg-tableStripeBg cursor-pointer px-4 p-3"
                        : "text-left text-[12px] cursor-pointer px-4 p-3"
                    } hoverTableCell`}
                    onClick={() => {
                      if (item.id > 0 && item.module == "trades") {
                        console.log("killTransactions select item ", item);
                        dispatch(updatesetSelectedID(item.id));
                        localStorage.setItem("current_selected_tid", item.link);
                        dispatch(
                          posttransactionDetailsData({
                            record_type: item.record_type,
                            id: item.link,
                          })
                        );
                        setRightHoldingDrawer(true);
                        setShow((prev) => !prev);
                      }
                    }}
                    key={index}
                  >
                    <div>
                      {item.title} ({item.description})
                    </div>
                    <span className="text-tabItemText">{item.date}</span>
                  </div>
                ))
              ) : null}
            </InfiniteScroll>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  notification: state.notification,
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
